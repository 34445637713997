<template>
  <div class="qa">
    <div class="qa-item" v-for="(item, index) in qaList" :key="index">
      <div class="title flex">
        <img :src="imgList[index]" class="img-icon">
        <div>{{item.title}}</div>
      </div>
      <div class="content">{{item.content}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qaList: [
        {title: '安卓手机可以在哪里下载村口壹站APP？', content: '(1) 应用市场有3家，包括应用宝、华为、小米；(2) 村口壹站微信公众号：村口科技--》村口APP下载。'},
        {title: '站长签约需提前准备哪些资料？', content:'身份证（原件或照片）、营业执照（原件或照片）、银行卡（原件或照片)。'},
        {title: 'APP闪退怎么办？', content:'建议重启后重新登录；若多次闪退，建议卸载APP重新安装；或联系村口壹站技术人员。'},
        {title: '站长可以在APP查看个人收益吗？', content:'可以。站长登录APP后，首页会显示站长的累计收益以及最新收益，站长需确认每月收益和开出发票，村口壹站财务在收到发票后按照公司付款流程支付站长收入。'},
      ],
      imgList: [require('@/assets/img/mine/q1.png'), require('@/assets/img/mine/q2.png'), require('@/assets/img/mine/q3.png'), require('@/assets/img/mine/q4.png')],
    }
  },
  
}
</script> 

<style lang="less" scoped>
.qa{
  background: #F7F8F9;
  min-height: 100%;
  padding: 0.24rem 0;
  .flex{
    display: flex;
  }
  .qa-item{
    background: #fff;
    padding: 0.36rem 0.3rem 0.3rem;
    border-bottom: 0.01rem solid #F7F8F9;
    .title{
      color: #4A4A4A;
      font-size: 0.32rem;
      font-weight: bold;
      align-items: center;
    }
    .content{
      color: #9B9B9B;
      font-size: 0.28rem;
      margin: 0.3rem 0.4rem 0;
    }
  }
  .img-icon{
    width: 0.4rem;
    height: 0.4rem;
    margin-right:0.1rem;
  }
}
</style>